import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createGlobalStyle } from "styled-components";
// ui
import Main from "pages/Main";
import MainLayout from "components/common/layouts";
import { Helmet, HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient();

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

`;

function App() {
  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Helmet>
            <meta
              property="og:url"
              content={process.env.REACT_APP_PUBLIC_URL}
              key="url"
            />
            <meta
              property="og:title"
              content={"가상자산 커뮤니티의 기준! 코알라"}
              key="title"
            />
            <meta
              property="og:description"
              content={"파일업 환율 계산기~"}
              key="description"
            />
            <meta
              property="og:image"
              content={`${process.env.REACT_APP_PUBLIC_S3_URL}/meta_thumbnail.png`}
              key="image"
            />
            <title>Coala fileup exchange</title>
            <link
              rel="icon"
              href={`${process.env.REACT_APP_PUBLIC_S3_URL}/meta/filecoin.ico`}
            />
          </Helmet>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Main />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </HelmetProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
