import React, { ChangeEvent, ReactElement, useState } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import useInput from "hooks/useInput";
import { useEffect } from "react";
import axios from "axios";
import {
  applyCommission,
  applyCommissionforDollar,
} from "utils/logic/exchange";
import { useRef } from "react";
import { debounce, setComma, uncomma } from "utils/tools";

const calInputResultColor = (type: string) => {
  switch (type) {
    case "USD":
      return "#dfdfdf";
    case "KWD":
      return "orange";
    default:
      return "#000000";
  }
};

const Styles = {
  MainWrap: styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .refetch-btn {
      display: block;
      height: 50px;
      border: none;
      cursor: pointer;
      margin-top: 10px;
      &:hover,
      &:focus {
        background-color: pink;
      }
    }
  `,
  CalInput: styled.div<any>`
    width: 360px;
    border: 2px solid #dfdfdf;

    .label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: 2px solid #dfdfdf;
      padding: 10px;
    }

    .input-box {
      height: 80px;
      font-size: 20px;
      text-align: right;
      padding: 10px;
      input {
        display: block;
        width: 100%;
        border: none;
        font-size: inherit;
        text-align: inherit;
        height: 50%;
      }
      .result {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 50%;
        color: ${(props) => calInputResultColor(props.type)};
      }
    }
  `,
  Equal: styled.div`
    height: 40px;
    position: relative;

    &::after,
    &::before {
      content: "";
      width: 50px;
      height: 10px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #000000;
    }
    &::after {
      top: calc(50% + 10px);
    }
    &::before {
      top: calc(50% - 10px);
    }
  `,
  CustomLabel: styled.div`
    display: inline-flex;
    align-items: center;
    span.image {
      display: inline-block;
      width: 30px;

      img {
        display: inline-block;
        width: 100%;
      }
    }
    span.text {
      margin-left: 5px;
    }
  `,
};

interface ICustomLabel {
  config: {
    imageSrc?: string;
    text: string;
  };
}

const CustomLabel = ({ config }: ICustomLabel) => {
  return (
    <Styles.CustomLabel>
      {config.imageSrc && (
        <span className="image">
          <img src={config.imageSrc} alt="label image" />
        </span>
      )}
      <span className="text">{config.text}</span>
    </Styles.CustomLabel>
  );
};

interface ICallInputProps {
  inputLabel: string | ReactElement;
  value: string;
  unit: string;
  onChange: () => {};
  onBlur?: any;
  disabled: boolean;
}

const CalInput = React.memo(function CalInput({
  inputLabel,
  value,
  unit,
  onChange,
  onBlur,
  disabled,
  ...props
}: ICallInputProps) {
  const checkUnit = (label: string) => {
    switch (label) {
      case "USD":
        return "달러";
      case "KRW":
        return "원";
      default:
        return "";
    }
  };
  const unitText = `${value} ${checkUnit(unit)}`;

  return (
    <Styles.CalInput type={unit}>
      <div className="label">{inputLabel}</div>
      <div className="input-box">
        <input
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          {...props}
        />
        <div className="result">{unitText}</div>
      </div>
    </Styles.CalInput>
  );
});

const callDollarwonApi = async () => {
  return await axios.get(
    "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  );
};

export default function Main() {
  const { isSuccess, isError, isLoading, isFetching, data, refetch } = useQuery(
    {
      queryKey: ["dollar-won"],
      queryFn: callDollarwonApi,
    }
  );
  const [dollarInput, setChangeDollar, onChangeDollar] = useInput({
    initValue: 0,
  });
  const [wonInput, setChangeWon, onChangeWon] = useInput({ initValue: 0 });
  const [isLoadingBounce, setIsloadingBounce] = useState(false);

  const onClickRefetchData = () => {
    setIsloadingBounce(false);
    refetch();
  };

  const debounceLoadingCheck = (cb: any) => () => {
    setIsloadingBounce(true);
    cb();
  };

  const onBlurSetComma =
    (_value: string | number, type: "dollar" | "won") => () => {
      const value = uncomma(_value);
      let res = "0";
      switch (type) {
        case "won":
          res = setComma(Math.floor(Number(value)));
          setChangeWon(res);
          break;
        case "dollar":
          res = setComma(Number(value).toFixed(2));
          setChangeDollar(res);
          break;
        default:
          break;
      }
    };

  const onKeyUpSetComma =
    (type: "dollar" | "won") => (e: ChangeEvent<HTMLInputElement>) => {
      const _value = e.target.value;
      const value = uncomma(_value);
      let res = "0";
      switch (type) {
        case "won":
          res = setComma(Math.floor(Number(value)));
          setChangeWon(res);
          break;
        case "dollar":
          res = setComma(Number(value).toFixed(2));
          setChangeDollar(res);
          break;
        default:
          break;
      }
    };

  const calcDollarToWon = (baseWon: number) => (_dollar: number) => {
    const dollar = uncomma(_dollar);
    const res = applyCommission(Number(dollar) * baseWon);
    const dropPoint = Math.floor(res);
    setChangeWon(dropPoint.toLocaleString("ko-KR"));
    // setChangeWon(setComma(dropPoint));
  };

  const calcWonToDollar = (baseWon: number) => (_won: number) => {
    const won = uncomma(_won);
    const res = Number(won) / applyCommission(baseWon);
    const toFix = res.toFixed(2);
    setChangeDollar(Number(toFix).toLocaleString("ko-KR"));
    // setChangeDollar(setComma(toFix));
  };

  const initCalc = () => {
    if (isError) {
      alert("환율 Api를 호출하는데 실패했습니다. 다시 시도해주세요");
      setChangeDollar(0);
      setChangeWon(0);
    }
    if (isSuccess) {
      const initDollar = 300;
      const won = data?.data[0].basePrice;
      setChangeDollar(setComma(Number(initDollar).toFixed(2)));
      setChangeWon(
        setComma(Math.floor(applyCommission(Number(initDollar * won))))
      );
    }
  };

  useEffect(initCalc, [data, isError]);

  if (isLoadingBounce) {
    return <div> loading...</div>;
  }
  return (
    <Styles.MainWrap>
      <div>
        <CalInput
          inputLabel={CustomLabel({
            config: {
              imageSrc: "/static/images/usa_flag.png",
              text: "미국 USD",
            },
          })}
          value={dollarInput}
          unit="USD"
          onChange={onChangeDollar(
            calcDollarToWon(data?.data[0].basePrice),
            "number-comma"
          )}
          onBlur={onBlurSetComma(dollarInput, "dollar")}
          disabled={isLoading}
        />
        <Styles.Equal />
        <CalInput
          inputLabel={CustomLabel({
            config: {
              imageSrc: "/static/images/kr_flag.png",
              text: "대한민국 KRW",
            },
          })}
          value={wonInput}
          unit="KRW"
          onChange={onChangeWon(
            calcWonToDollar(data?.data[0].basePrice),
            "number-comma"
          )}
          onBlur={onBlurSetComma(wonInput, "won")}
          disabled={isLoading}
        />
      </div>
      <button
        className="refetch-btn"
        onClick={debounceLoadingCheck(debounce(onClickRefetchData, 1000))}
      >
        환율 갱신하기
      </button>
    </Styles.MainWrap>
  );
}
