export const makeComma = (str:any):string => {
    str = String(str);
    const [int, decimal] = str.split('.');
    return decimal ? `${int.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")}.${decimal}` : int.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
};

// 숫자에서 자릿수 제거 (소수점은 남김)
export const uncomma = (str:any):string => {
    str = String(str);
    const [int, decimal] = str.split('.');
    return decimal ? `${int.replace(/[^\d]+/g, "")}.${decimal}` : int.replace(/[^\d]+/g, "");
};

export const setComma = (str:any):string => {
    if(!Number.isInteger(Number(str))) {
        return makeComma(uncomma(str));
    }
    return makeComma(uncomma(str))
}

export const debounce = (cb:any, limit = 100) => {
    let timeout:any = null;
    return (...args:any) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            cb(...args);
        }, limit);
    }
}

export const throttle = (cb:any, limit = 100) => {
    let timeout:any = null;
    return (...args:any) => {
        if(!timeout) {
            timeout = setTimeout(() => {
                cb(...args);
                clearTimeout(timeout);
            }, limit);
        }
    }
}