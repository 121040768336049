import React, { ChangeEvent, useCallback, useState } from "react";
import { uncomma } from "utils/tools";

interface IProps {
  initValue: any,
}

export default function useInput(props: IProps) {
  const [state, setState] = useState(props.initValue);

  const handleInput = (cb:any, type='') => {
      return (event: ChangeEvent<HTMLInputElement>) => {
        let value = null;
        switch(type) {
          case 'number-comma':
            value = Number(uncomma(event.target.value)).toLocaleString('ko-KR')
            break;
          default:
            value = event.target.value;
            break;
        }
        setState(value);
        cb && cb(value);
    }
  }

  const setChangeState = (value:any) => {
    setState(value);
  }

  return [state, setChangeState, handleInput];
}
