import React from "react";
import { Outlet } from "react-router";
import styled from "styled-components";

const Styles = {
  MainWrap: styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
  `,
  Mainbox: styled.div`
    max-width: 720px;
    width: 100%;
    border: 1px solid #000000;
    margin: 30px 0;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
};
export default function MainLayout() {
  return (
    <Styles.MainWrap>
      <Styles.Mainbox>
        <h1>파일업 환율 계산기</h1>
        <Outlet />
      </Styles.Mainbox>
    </Styles.MainWrap>
  );
}
