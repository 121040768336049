export const applyCommission = (value:any):number => {
    const commission = 1.01;
    return Number(value) * commission;
}

// 1달러 = 원 => 1원 = 달러
export const wonDollarChange = (won: any):number => {
    const _won = typeof won === 'string' ? Number(won): won;
    const dollar = 1;
    const wonDollar = applyCommission(dollar / _won).toFixed(5);
    return Number(wonDollar);
}

type TPrice = string|number;

export const applyCommissionforDollar = (basePrice:TPrice, dollar:TPrice):number => {
    return Number(dollar) / applyCommission(basePrice);
}